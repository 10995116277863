.Testimonials {
    display: flex;
    gap: 1rem;
    padding: 0.2rem
}

.left-t {
    display: flex;
    margin-left: 20px;
    flex: 1 1;
    gap: 2rem;
    flex-direction: column;
    text-transform: uppercase;
    color: white;
}

.left-t>:nth-child(1) {
    color: orange;
    font-weight: bold;
}

.left-t>:nth-child(2),.left-t>:nth-child(3) {
    font-weight: bold;
    font-size: 3rem;
}

.left-t>:nth-child(4) {
    text-transform: none;
    text-align: justify;
    letter-spacing: 2px;
    line-height: 40px;
}

.right-t{
    flex: 1;
    position: relative;
}

.right-t>img {
    position: absolute;
    width: 17rem;
    height: 20rem;
    object-fit: cover;
    right: 8rem;
    top: 2rem;
}

.right-t>:nth-child(1) {
    position: absolute;
    width: 17rem;
    height: 20rem;
    border: 2px solid orange;
    background-color: transparent;
    right: 9rem;
    top: 0.9rem;
}

.right-t>:nth-child(2) {
    width: 17rem ;
    height: 19rem;
    top: 4rem;
    right: 7rem;
    background: var(--planCard);
    position: absolute;
}

.arrows {
    display: flex;
    gap: 1rem;
    position: absolute;
    bottom: 1rem;
    left: 3rem;
}

.arrows>img {
    width: 1.5rem;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .Testimonials{
        flex-direction: column;
        margin-top: -7rem;
        
    }

    .left-t>:nth-child(2)
    ,.left-t>:nth-child(3){
        font-size: xx-large;
        
    }

    .left-t>:nth-child(4) {
        margin-right: 2rem;
    }

    .right-t{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2rem;
    }

    .right-t>div {
        position: relative;
        display: none;
    }

    .right-t>img {
        right: 0;
        position: relative;
        align-self: center;
        top: 0;
    }

    .right-t>:last-child{
        display: block;
        bottom: 0;
        left: 0;
    }
}











