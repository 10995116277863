.Footer-container {
    position: relative;
}

.Footer-container>hr{
    border: 1px solid var(--lightgray);
}

.footer {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    height: 37rem;
}

.social-links {
    display: flex;
    gap: 4rem;
   
}

.location {
    margin-top: -2rem;
    width: fit-content;
}

.location h2, h3 {
    color: white;
    display: block;
    align-items: center;
    justify-content: center;
}

.opening-hours {
    width: fit-content;
}

.opening-hours h2 {
    color: white;
}

.opening-hours span{
    display: block;
    margin-top: 5px;
    color: white;

}

.social-links> img {
     width: 2rem;
    height: 2rem;
    cursor: pointer;
}

.logo-f>img {
    width: 10rem;
    border: none;
    outline: none;
    border-radius: 40%;
}

.blur-f-1 {
    bottom: 0;
    right: 15%;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);
    background: red;
}

.blur-f-2 {
    bottom: 0;
    right: 15%;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);
    background: red;
}

@media screen and (max-width: 768px) {
    .opening-hours {
        margin-top: 4rem;
    }

    .social-links> img{
        margin-top: -2rem;
    }

    .logo-f>img {
        margin-top: -2rem;
        margin-bottom: 6rem;
    }
}